import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import Modal from "../../../components/Modal";
import { AssociatesType, associates } from "../../../mocks/associates";
import {
  AssociatePhoto,
  Boats,
  Description,
  Dot,
  Dots,
  ModalContent,
  NoBoats,
  SliderBox,
} from "./style";
import Slider from "react-slick";
import Avatar from "../../../assets/images/associates/avatar.png";
import DefaultBoat from "../../../assets/images/associates/defaultBoat.png";

type AssociatesModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  associate?: AssociatesType;
};

export default function AssociatesModal({
  isOpen,
  setIsOpen,
  associate,
}: AssociatesModalProps) {
  const sliderRef = useRef<any>(null);
  const settings = {
    initialSlide: 0,
    dots: false,
    infinite: associate ? associate?.boatNames.length > 1 : false,
    autoplay: true,
    draggable: true,
    slidesToScroll: 1,
    arrows: associate ? associate?.boatNames.length > 1 : false,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          dots: associate ? associate?.boatNames.length > 1 : false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => (
      <Dots>
        <Dot> {dots} </Dot>
      </Dots>
    ),
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <ModalContent>
        <AssociatePhoto
          src={associate?.photo ?? Avatar}
          alt="associate photo"
        />
        <h1>{associate?.name}</h1>
        <SliderBox>
          <Slider {...settings} ref={sliderRef}>
            {associates && associate?.boatNames.length ? (
              associate?.boatNames.map((name, index) => (
                <Boats key={index}>
                  <img
                    src={associate?.boatImages[index] ?? DefaultBoat}
                    alt={associate?.boatNames[index]}
                  />
                  <span>Barco: {name}</span>
                  {associate?.descriptions[index] ? (
                    <Description>
                      <p>{associate?.descriptions[index]}</p>
                    </Description>
                  ) : null}
                </Boats>
              ))
            ) : (
              <NoBoats>
                Esse associado está sem embarcagens cadastradas.
              </NoBoats>
            )}
          </Slider>
        </SliderBox>
      </ModalContent>
    </Modal>
  );
}
