import { AssociatesType } from "../../../mocks/associates";
import { SlideContainer } from "./style";
import Default from "../../../assets/images/associates/avatar.png";

type SlidePackegesProps = {
  pack: AssociatesType;
  onClick: () => void;
  props: any;
};

export default function Slide({ props, pack, onClick }: SlidePackegesProps) {
  const { index, ...otherProps } = props;

  return (
    <SlideContainer {...otherProps} onClick={onClick}>
      <img src={pack.photo ? pack.photo : Default} alt="associate photo" />
      <p>{pack.name}</p>
    </SlideContainer>
  );
}
