import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

type CardProps = {
  background: string;
};

type HighlightImageProps = {
  background: string;
};

export const Section = styled.div`
  padding: 32px 0;
  font-family: ${FONT_FAMILY.INTER};

  .infos {
    padding-top: 64px;
    background-color: ${COLORS.BLUE};
  }
`;

export const Description = styled.div`
  font-size: 28px;
  color: ${COLORS.BLUE};
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  margin: 0;
  flex: 0 0 30%;
  align-self: flex-start;

  p {
    padding: 0px 0px 24px 0px;
    text-align: left;
    line-height: 32px;
  }

  span {
    text-align: center;
  }

  @media (max-width: 1400px) {
    font-size: 24px;
  }

  @media (max-width: 1200px) {
    p {
      text-align: center;
    }
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;

  @media (max-width: 1200px) {
    grid-template-columns: 100%;
    gap: 18px;
    padding-top: 18px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: 0.4s all;

  &:hover {
    transition: 0.4s all;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const CardImage = styled.div<CardProps>`
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 18px;
  color: ${COLORS.WHITE};
`;

export const CardInfo = styled.div`
  padding: 18px;
  text-align: start;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${COLORS.BLUE};

  p {
    display: -webkit-box;
    font-size: 18px;
    margin: 8px 0;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    color: ${COLORS.BLUE};
  }

  span {
    display: flex;
    align-items: center;

    a {
      color: ${COLORS.BLUE};
      text-decoration: none;
      margin-left: auto;
      font-weight: ${FONT_WEIGHT.BOLD};
      color: #68b3f2;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 18px 0;
    width: 90%;
    margin: 0 auto;
  }
`;

export const Highlight = styled.div`
  flex: 0 0 62%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);

  &:hover {
    transition: 0.4s all;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 1200px) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

export const HighlightBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;

  p {
    font-size: 24px;
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    font-family: ${FONT_FAMILY.INTER};
    letter-spacing: 1px;
    margin-top: 8px;
  }

  span {
    font-size: 14px;
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1200px) {
    padding: 24px 0;
    width: 90%;
    margin: 0 auto;

    p {
      font-size: 22px;
    }
  }
`;

export const HighlightImage = styled.div<HighlightImageProps>`
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${COLORS.WHITE};
  border-radius: 10px;
  position: relative;

  @media (max-width: 1400px) {
    height: 380px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Label = styled.div`
  color: ${COLORS.BLUE};
  padding: 4px 24px;
  border-radius: 30px;
  border-color: #000;
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  background-color: #68b3f2;
  width: fit-content;
  text-transform: uppercase;

  &.Destaque {
    opacity: 0.8;
    margin-left: 8px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.RED};
  }

  &.Artigo {
    background-color: #a880ba;
  }
`;

export const ClockImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;
