import Button from "../../../components/Button";
import Container from "../../../components/Container";
import { useLanguageContext } from "../../../contexts/languageContext";
import { DataTexts } from "../../../mocks/texts";
//import { kekData } from "../../../mocks/kekData";
import { BackgroundImage, Section } from "./style";

export default function Contact() {
  const { lang } = useLanguageContext();

  return (
    <BackgroundImage>
      <Container>
        <Section>
          <div>
            <p>{DataTexts.title[lang]}</p>
            <p className="sub-texto">{DataTexts.desc[lang]}</p>
            <Button
              title={DataTexts.btn[lang]}
              width={280}
              href="estatisticas"
            />
          </div>
        </Section>
      </Container>
    </BackgroundImage>
  );
}
