import { useLanguageContext } from "../../contexts/languageContext";
import { CopyRightText } from "../../mocks/texts";
import { Section } from "./style";

export default function Copyright() {
  const { lang } = useLanguageContext();

  return (
    <Section>
      © 2024 ABRAPPAA. {CopyRightText[lang]}:{" "}
      <a href="https://www.tensortech.com.br/" target="_blank">
        Tensortech
      </a>
    </Section>
  );
}
