import AnnaKarolineThairo from "../assets/images/associates/thairo/ANNAKAROLINE.png";
import ThaciaIThairo from "../assets/images/associates/thairo/THACIAI.png";


import Nortemar_AnaKarolline from "../assets/images/associates/nortemar/ANAKAROLLINEI.png";
import Nortemar_CapitaoLuiz from "../assets/images/associates/nortemar/CAPITAOLUIZI.png";
import Nortemar_CostaDourada from "../assets/images/associates/nortemar/COSTADOURADAX.png";
import Nortemar_Recife from "../assets/images/associates/nortemar/RECIFEX.png";
import Nortemar_SaoGabriel from "../assets/images/associates/nortemar/SAOGABRIELV.png";

export type AssociatesType = {
  name: string;
  boatNames: string[];
  boatImages: string[];
  descriptions: string[];
  photo?: string;
};

export const associates: AssociatesType[] = [
  {
    name: "Thairo Barroso Bastos de Santiago",
    boatNames: ["Anna Karoline","Thacia I", "Marilia Santiago", "Thacia"],
    boatImages: [AnnaKarolineThairo, ThaciaIThairo],
    descriptions: [],
  },
  {
    name: "Nortemar Comércio e Exportação LTDA",
    boatNames: ["Capitão Luiz I", "Costa Dourada X", "Recife X", "São Gabriel V", "Ana Karolline I", "São Rafael X", "Maragogi",  "São Miguel"],
    boatImages: [Nortemar_CapitaoLuiz, Nortemar_CostaDourada, Nortemar_Recife, Nortemar_SaoGabriel,Nortemar_AnaKarolline],
    descriptions: [],
  },
  {
    name: "Antonio Xavier de Sousa",

    boatNames: ["Pitia", "Rochelle","Samiro Pesca I"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Gilson Martins Gomes",
    boatNames: ["Comte Moisés III", "Milagres", "Milagres I"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Fernando Sipião de Moura",
    boatNames: ["Utemar"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Samia Soares Lima",
    boatNames: [
      "Morgana I",
      "Morgana III",
      "Morgana IV",
      "Morgana V",
      "Morgana VI",
      "Morgana VIII",
    ],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Karia Maria Risuenho Abdon",
    boatNames: [
      "Nova vida com jesus IV",
      "Pato Filho I",
      "Geleira Ajuruteua IV",
    ],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "João Waldemar Risuenho Abdon",
    boatNames: [
      "Geleira Ajuruteua",
      "Geleira Ajuruteua II",
      "Geleira Ajuruteua III",
      "Geleira Ajuruteua V",
      "Geleira Ajuruteua VI",
      "Geleira Ajuruteua VII",
      "Geleira Ajuruteua VIII",
      "Geleira Ajuruteua IX",
      "Carlos Alberto II",
    ],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Alysson Martins e Santiago",
    boatNames: ["Alysson"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Maurilio Ivan Amarante de Santiago",
    boatNames: [
      "Teresa de Jesus",
      "Maurilio Filho",
      "Maurilio",
      "João Neto de Bragança",
    ],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Maurilio Ivan Amarante de Santiago Filho",
    boatNames: ["Thairo"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Suzana Patricia Barroso Bastos",
    boatNames: ["Anna Luisa I"],
    boatImages: [],
    descriptions: [],
  },

  {
    name: "Francisco Erislan da Silva de Oliveira",
    boatNames: ["Iriai"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Gilvan de P Silva",
    boatNames: [
      "Garoupa",
      "Garoupa III",
      "Garoupa V",
      "Garoupa VI",
      "Hivys",
      "Líder V",
      "Ponto da Pesca I",
      "Ponto da Pesca III",
      "Lobao I",
      "Recruta Zero",
    ],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "João José Martins Gomes",
    boatNames: ["Magia II"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "José Otávio da Silva",
    boatNames: ["Urumair", "Sao Manoel V"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "J. S. de Freitas Pescados ME",
    boatNames: ["Mellina"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Raimundo Rodrigues da Rocha",
    boatNames: ["Darlav IV"],
    boatImages: [],
    descriptions: [],
  },
  {
    name: "Sandro Manuel Borges Teixeira",
    boatNames: ["San Sebastian III"],
    boatImages: [],
    descriptions: [],
  },
];
