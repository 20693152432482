import { useState } from "react";

import Container from "../Container";
import Button from "../Button";

import {
  Flex,
  FlexSpaced,
  Head,
  HeaderProps,
  Item,
  Languages,
  LogoImg,
  MenuImg,
  SelectedLang,
} from "./style";

import Logo from "../../assets/images/logo.svg";
import Menu from "../../assets/images/menu.svg";
import Close from "../../assets/images/close.svg";
import PT from "../../assets/images/pt.png";
import EN from "../../assets/images/en.png";
import ES from "../../assets/images/es.png";
import { useLanguageContext } from "../../contexts/languageContext";
import { MenuTexts } from "../../mocks/texts";
import { useNavigate } from "react-router-dom";
import { linkForms } from "../../mocks/abrappaData";
import { handleMenuClick } from "../../utils/scrollTo";

export default function Header({
  notHome = false,
  topRef,
  newsRef,
  aboutRef,
}: HeaderProps) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const { toggleLanguage, lang } = useLanguageContext();

  return (
    <Head notHome={notHome}>
      <Container>
        <SelectedLang>
          <Languages onClick={() => toggleLanguage(0)} src={PT} alt="pt" />
          <Languages onClick={() => toggleLanguage(1)} src={EN} alt="en" />
          <Languages onClick={() => toggleLanguage(2)} src={ES} alt="es" />
        </SelectedLang>
      </Container>
      <Container>
        <FlexSpaced>
          <LogoImg
            src={Logo}
            alt="ABRAPPAA logo"
            onClick={() => {
              if (notHome) {
                navigate("/");
              } else {
                handleMenuClick(topRef);
                setOpenMenu(false);
              }
            }}
          />
          <MenuImg
            src={!openMenu ? Menu : Close}
            alt="menu"
            onClick={() => setOpenMenu(!openMenu)}
          />
          <Flex isOpen={openMenu}>
            <Item
              onClick={() => {
                if (notHome) {
                  navigate("/");
                } else {
                  handleMenuClick(topRef);
                  setOpenMenu(false);
                }
              }}
            >
              {MenuTexts.inicio[lang]}
            </Item>
            {!notHome && (
              <>
                <Item
                  onClick={() => {
                    handleMenuClick(newsRef);
                    setOpenMenu(false);
                  }}
                >
                  {MenuTexts.noticias[lang]}
                </Item>
                <Item
                  onClick={() => {
                    handleMenuClick(aboutRef);
                    setOpenMenu(false);
                  }}
                >
                  {MenuTexts.quemSomos[lang]}
                </Item>
                <Item onClick={() => window.open("estatisticas")}>
                  {MenuTexts.estatisticas[lang]}
                </Item>
              </>
            )}
            <Button
              title={MenuTexts.associe[lang]}
              width={148}
              height={42}
              fontSize={16}
              href={linkForms}
              target="_blank"
              rel="noreferrer"
            />
          </Flex>
        </FlexSpaced>
      </Container>
    </Head>
  );
}
