import { AboutInfo, Background, Flex } from "./style";
import AboutImg from "../../../assets/images/about.png";
import Container from "../../../components/Container";
import Button from "../../../components/Button";
import { AboutTexts } from "../../../mocks/texts";
import { useLanguageContext } from "../../../contexts/languageContext";
import { SectionProps } from "../../../types/section";
import { abrappaData } from "../../../mocks/abrappaData";

export default function About({ referance }: SectionProps) {
  const { lang } = useLanguageContext();

  return (
    <Background ref={referance}>
      <Container>
        <Flex>
          <AboutInfo>
            <div>
              <h1>{AboutTexts.title[lang]}</h1>
              <p>{AboutTexts.desc[lang]}</p>
              <Button
                title={AboutTexts.btn[lang]}
                href={abrappaData.whatsapp}
                target="_blank"
                rel="noreferrer"
              />
            </div>
          </AboutInfo>
          <img src={AboutImg} alt="about image" />
        </Flex>
      </Container>
    </Background>
  );
}
