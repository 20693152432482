import { styled } from "styled-components";
import Fish from "../../../assets/images/fish.png";
import { FONT_FAMILY, FONT_WEIGHT } from "../../../theme";

export const BackgroundImage = styled.div`
  background-image: url(${Fish});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Section = styled.div`
  width: 100%;
  padding: 84px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 64px 0;
  }

  div {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    font-family: ${FONT_FAMILY.MONTSERRAT};

    p {
      font-weight: ${FONT_WEIGHT.BOLD};
      font-size: 32px;
      color: #fff;
      margin-bottom: 18px;

      @media (max-width: 1400px) {
        font-size: 28px;
      }

      @media (max-width: 950px) {
        font-size: 24px;
      }
    }

    .sub-texto {
      font-size: 18px;
      font-weight: ${FONT_WEIGHT.REGULAR};
      margin-bottom: 56px;

      @media (max-width: 950px) {
        margin-bottom: 28px;
        font-size: 16px;
      }
    }
  }
`;
