import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import Container from "../../../components/Container";
import { Dot, Dots, Flex, GetToKnow, Section, SliderBox, Title } from "./style";
import Slider from "react-slick";
import Slide from "./slide";
import { associates } from "../../../mocks/associates";
import { AssociatesTexts } from "../../../mocks/texts";
import { useLanguageContext } from "../../../contexts/languageContext";
import AssociatesModal from "./modal";

export default function Associates() {
  const { lang } = useLanguageContext();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState<string>("");

  const handleChangeAssociate = () => {
    return associates.find((associate) => associate.name === selectedAssociate);
  };

  const sliderRef = useRef<any>(null);
  const settings = {
    initialSlide: 0,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    appendDots: (dots: any) => (
      <Dots>
        <Dot> {dots} </Dot>
      </Dots>
    ),
  };

  return (
    <>
      <Section>
        <Container>
          <Flex>
            <Title>{AssociatesTexts[lang]}</Title>
            <SliderBox>
              <Slider {...settings} ref={sliderRef}>
                {associates.map((d, index) => (
                  <Slide
                    pack={d}
                    props={index}
                    onClick={() => {
                      setSelectedAssociate(d.name);
                      setIsOpen(true);
                    }}
                  />
                ))}
              </Slider>
            </SliderBox>
            <GetToKnow href="associados">Conheça nossos associados</GetToKnow>
          </Flex>
        </Container>
      </Section>
      <AssociatesModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        associate={handleChangeAssociate()}
      />
    </>
  );
}
