import Button from "../../../components/Button";
import Container from "../../../components/Container";
import { useLanguageContext } from "../../../contexts/languageContext";
import { linkForms } from "../../../mocks/abrappaData";
import { RegisterTexts } from "../../../mocks/texts";
import { BackgroundImage, Section } from "./style";

export default function Contact() {
  const { lang } = useLanguageContext();

  return (
    <BackgroundImage>
      <Container>
        <Section>
          <div>
            <p>{RegisterTexts.title[lang]}</p>
            <p className="sub-texto">{RegisterTexts.desc[lang]}</p>
          </div>
          <Button
            title={RegisterTexts.btn[lang]}
            width={320}
            href={linkForms}
            target="_blank"
            rel="noreferrer"
          />
        </Section>
      </Container>
    </BackgroundImage>
  );
}
