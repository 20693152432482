import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

export const Section = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px - 296px - 52px);
  padding: 48px 0;
  font-family: ${FONT_FAMILY.MONTSERRAT};
`;

export const Container = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 950px) {
    width: 90%;
  }
`;

export const Title = styled.h1`
  color: ${COLORS.BLUE};
  text-align: center;
`;

export const NewsImg = styled.img`
  width: 100%;
  border-radius: 20px;
`;

export const Label = styled.div`
  color: ${COLORS.BLUE};
  padding: 4px 24px;
  border-radius: 30px;
  border-color: #000;
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  background-color: #68b3f2;
  width: fit-content;
  text-transform: uppercase;

  &.Destaque {
    opacity: 0.8;
    margin-left: 8px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.RED};
  }

  &.Artigo {
    background-color: #a880ba;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  &.spaced {
    justify-content: space-between;
    padding: 16px 0 32px 0;
  }

  span {
    color: ${COLORS.BLUE};
    font-size: 14px;
  }
`;

export const Clock = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

export const NoNews = styled.span`
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-top: 48px;
`;

export const Texts = styled.div`
  width: 100%;
  margin-top: 24px;

  p {
    margin-top: 16px;
    line-height: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
