import { styled } from "styled-components";
import { FONT_FAMILY, FONT_WEIGHT } from "../../../theme";

export const BackgroundImage = styled.div`
  background-color: #302a66;

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 64px 0;
    background-position: left;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 84px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  div {
    display: flex;
    flex-direction: column;

    @media (max-width: 950px) {
      width: 100%;
      text-align: center;
      margin-bottom: 18px;
    }

    p {
      font-family: ${FONT_FAMILY.MONTSERRAT};
      font-weight: ${FONT_WEIGHT.BOLD};
      font-size: 32px;
      color: #fff;
      margin-bottom: 18px;

      @media (max-width: 1400px) {
        font-size: 28px;
      }

      @media (max-width: 950px) {
        font-size: 24px;
      }
    }

    .sub-texto {
      font-size: 18px;
      font-weight: ${FONT_WEIGHT.REGULAR};

      @media (max-width: 950px) {
        font-size: 16px;
      }
    }
  }
`;
