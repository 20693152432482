import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Section, Title } from "./style";

export default function Stats() {
  return (
    <>
      <Header notHome />
      <Section>
        <Container>
          <Title>Estatísticas</Title>
          <iframe
            title="Associação"
            src="https://app.powerbi.com/view?r=eyJrIjoiYjc4MTZjYmMtMmRjZS00OTNmLWFiNzItNGUwOTU1Zjg0OTI4IiwidCI6IjBjMWFlMzAyLTZhODEtNDk1MC1hYzY1LWU3YzM3YTNlNTc4MSJ9"
            allowFullScreen
          ></iframe>
        </Container>
      </Section>
      <Footer />
    </>
  );
}
