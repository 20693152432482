import { useState } from "react";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { associates } from "../../mocks/associates";
import {
  AssociateBox,
  AssociateContainer,
  AssociateMember,
  Section,
  Title,
} from "./style";
import Avatar from "../../assets/images/associates/avatar.png";
import AssociatesModal from "../Home/Associates/modal";

export default function Associados() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState("");

  const handleChangeAssociate = () => {
    return associates.find((associate) => associate.name === selectedAssociate);
  };

  return (
    <>
      <Header notHome />
      <Container>
        <Section>
          <Title>Nossos Associados</Title>
          <AssociateContainer>
            {associates.map((associate) => (
              <AssociateMember
                onClick={() => {
                  setSelectedAssociate(associate.name);
                  setIsOpen(true);
                }}
              >
                <AssociateBox>
                  <img src={associate.photo ?? Avatar} alt="associate photo" />
                  <p>{associate.name}</p>
                </AssociateBox>
              </AssociateMember>
            ))}
          </AssociateContainer>
        </Section>
      </Container>
      <Footer />
      <AssociatesModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        associate={handleChangeAssociate()}
      />
    </>
  );
}
