import React from "react";
import { BackgoundModal, CloseModal, ModalBox, ModalContainer } from "./style";
import Close from "../../assets/images/close.svg";

type ModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

export default function Modal({ open, setOpen, children }: ModalProps) {
  return open ? (
    <BackgoundModal>
      <ModalBox>
        <CloseModal
          src={Close}
          alt="close modal"
          onClick={() => setOpen(false)}
        />
        <ModalContainer>{children}</ModalContainer>
      </ModalBox>
    </BackgoundModal>
  ) : (
    <></>
  );
}
