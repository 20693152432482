import { useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import About from "./About";
import Associates from "./Associates";
import Datas from "./Datas";
import Hero from "./Hero";
import News from "./News";
import Register from "./Register";

export default function Home() {
  const topRef = useRef<any>(null);
  const newsRef = useRef<any>(null);
  const aboutRef = useRef<any>(null);

  return (
    <>
      <Header topRef={topRef} newsRef={newsRef} aboutRef={aboutRef} />
      <Hero referance={topRef} goTo={aboutRef} />
      <News referance={newsRef} />
      <Associates />
      <About referance={aboutRef} />
      <Datas />
      <Register />
      <Footer />
    </>
  );
}
