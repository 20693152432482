import { styled } from "styled-components";

export const Context = styled.div`
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }
`;
