import Container from "../../../components/Container";
import {
  Card,
  Cards,
  Description,
  Section,
  Flex,
  Highlight,
  HighlightImage,
  CardImage,
  CardInfo,
  Label,
  ClockImage,
  HighlightBox,
} from "./style";
import Button from "../../../components/Button";
import { useLanguageContext } from "../../../contexts/languageContext";
import WhiteClock from "../../../assets/images/white-clock.svg";
import Clock from "../../../assets/images/relogio.svg";
import { SectionProps } from "../../../types/section";
import { NewsTexts, allNews, seeMore } from "../../../mocks/newsTexts";
import { newsLink } from "../../../utils/newsLink";

export default function Intro({ referance }: SectionProps) {
  const { lang } = useLanguageContext();

  const highlightNew = allNews.find((hNew) => hNew.type[0] === "Destaque");
  const otherNews = allNews
    .filter((hNew) => hNew.type[0] !== "Destaque")
    .slice(0, 3);

  return (
    <Section ref={referance}>
      <Container>
        <Flex>
          <Description>
            <p>{NewsTexts.title[lang]}</p>
            <Button title={NewsTexts.btn[lang]} href="allnews" />
          </Description>
          {highlightNew && (
            <Highlight
              onClick={() =>
                window.open(
                  `noticia/${newsLink(highlightNew?.title[0])}`,
                  "_blank"
                )
              }
            >
              <HighlightImage background={highlightNew?.image}>
                <HighlightBox>
                  <Label className={highlightNew?.type[0]}>
                    {highlightNew?.type[lang]}
                  </Label>
                  <p>{highlightNew?.title[lang]}</p>
                  <span>
                    <ClockImage src={WhiteClock} alt="clock" />
                    {highlightNew?.date[lang]}
                  </span>
                </HighlightBox>
              </HighlightImage>
            </Highlight>
          )}
        </Flex>

        <div className="cards">
          <Cards>
            {otherNews.map((news) => (
              <Card
                onClick={() =>
                  window.open(`noticia/${newsLink(news?.title[0])}`, "_blank")
                }
              >
                <CardImage background={news.image} />
                <CardInfo>
                  <Label className={news?.type[0]}>{news.type[lang]}</Label>
                  <p>{news.title[lang]}</p>
                  <span>
                    <ClockImage src={Clock} alt="clock" />
                    {news.date[lang]}
                    <a href="#">{seeMore[lang]} →</a>
                  </span>
                </CardInfo>
              </Card>
            ))}
          </Cards>
        </div>
      </Container>
    </Section>
  );
}
