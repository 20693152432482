import Button from "../../../components/Button";
import Container from "../../../components/Container";
import { useLanguageContext } from "../../../contexts/languageContext";
import { abrappaData } from "../../../mocks/abrappaData";
import { HeroTexts } from "../../../mocks/texts";
import { SectionProps } from "../../../types/section";
import { handleMenuClick } from "../../../utils/scrollTo";
import { BackgroundImg, Desc, Infos, Title } from "./style";
import { useRef } from "react";

type HeroProps = SectionProps & {
  goTo: React.MutableRefObject<any>;
};

export default function Hero({ referance, goTo }: HeroProps) {
  const { lang } = useLanguageContext();

  return (
    <BackgroundImg ref={referance}>
      <Container>
        <Infos>
          <Title>{HeroTexts.title[lang]}</Title>
          <Desc>{HeroTexts.desc[lang]}</Desc>
          <Button
            familyInter
            title={HeroTexts.btn[lang]}
            fontSize={20}
            href="#"
            onClick={() => handleMenuClick(goTo)}
          />
        </Infos>
      </Container>
    </BackgroundImg>
  );
}
