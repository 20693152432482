import styled from "styled-components";

export const BackgoundModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 999;
`;

export const ModalBox = styled.div`
  background-color: #fff;
  width: 800px;
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 950px) {
    width: 90%;
  }
`;

export const CloseModal = styled.img`
  width: 24px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  margin-left: auto;
`;

export const ModalContainer = styled.div`
  padding: 0 16px 16px 16px;
`;
