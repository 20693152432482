import { ButtonProps, Content } from "./style";

export default function Button({
  title = "",
  familyInter = false,
  width,
  height,
  fontSize,
  ...rest
}: ButtonProps) {
  return (
    <Content
      familyInter={familyInter}
      width={width}
      height={height}
      fontSize={fontSize}
      {...rest}
    >
      {title}
    </Content>
  );
}
