import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Languages } from "../mocks/texts";

type ProviderType = {
  children: React.ReactNode;
};

type LanguageContextType = {
  lang: Languages;
  toggleLanguage: (changeLang: Languages) => void;
};

const LanguageContext = createContext<LanguageContextType>(
  {} as LanguageContextType
);

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children }: ProviderType) => {
  const Lang: number = Number(localStorage.getItem("lg")) || Languages.PT;
  const [lang, setLang] = useState<Languages>(Lang);

  const toggleLanguage = useCallback((changeLang: Languages) => {
    setLang(changeLang);
    localStorage.setItem("lg", changeLang.toString());
  }, []);

  const values = useMemo(() => {
    return {
      lang,
      toggleLanguage,
    };
  }, [lang, toggleLanguage]);

  return (
    <LanguageContext.Provider value={values}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
