import Pargo from "../assets/images/news/pargo.png";
import Presidente from "../assets/images/news/presidente.png";
import Peixes from "../assets/images/news/peixes.png";
import DiaDasMaes from "../assets/images/news/diadasmaes.png";

export const NewsTexts = {
  title: [
    "FIQUE POR DENTRO DAS NOSSAS NOTÍCIAS E ARTIGOS",
    "STAY UP TO DATE WITH OUR NEWS AND ARTICLES",
    "MANTÉNGASE AL DÍA DE NUESTRAS NOTICIAS Y ARTÍCULOS",
  ],
  btn: ["Veja todas a matérias", "See all articles", "Ver todos los artículos"],
};

const types = {
  hightlight: ["Destaque", "Highlight", "Énfasis"],
  news: ["Notícia", "News", "Noticias"],
  article: ["Artigo", "Article", "Artículo"],
};

export const allNews = [
  {
    type: types.hightlight,
    title: [
      "Foi dada a largada na pesca de Pargo",
      "Pargo fishing has started",
      "La pesca del pargo ha comenzado",
    ],
    date: ["01 de maio de 2024", "May 1, 2024", "01 de mayo de 2024"],
    desc: [
      [
        "Após meses de expectativa e preparação, a temporada de pesca de Pargo finalmente começou! Ontem, com um clima ideal e uma atmosfera de entusiasmo contagiante, pescadores de toda a região se reuniram para dar início a essa tradicional e aguardada temporada.",
        "O evento de lançamento contou com a participação de associados da nossa associação de pescadores, bem como entusiastas e profissionais da pesca. A cerimônia, realizada na bela costa local, marcou o início oficial da temporada com uma competição amigável e a expectativa de grandes capturas.",
        "“Estamos animados para mais uma temporada. O Pargo é uma espécie que traz desafios e alegrias para os pescadores”, disse João Silva, presidente da Associação de Pescadores. “Com as condições favoráveis e o trabalho árduo de todos os envolvidos na preparação, temos certeza de que será uma temporada incrível.”",
        "Além das competições e desafios para os maiores peixes, a temporada também traz oportunidades para a promoção da pesca sustentável e a preservação dos ecossistemas marinhos. A associação enfatiza a importância de respeitar as regras de captura e soltura, garantindo a saúde dos estoques pesqueiros para as futuras gerações.",
        "Os pescadores têm até o final de setembro para participar das competições e aproveitar ao máximo a temporada. A expectativa é alta, e os relatos iniciais indicam uma abundância promissora de Pargo nas águas locais.",
        "Para mais informações sobre eventos, regulamentos e dicas de pesca, os interessados podem visitar nosso site ou entrar em contato com a associação diretamente. Boa sorte a todos os pescadores e que venha uma temporada repleta de grandes histórias e belos troféus!",
      ],
      [
        "After months of anticipation and preparation, the Pargo fishing season has officially begun! Yesterday, with ideal weather and an atmosphere of contagious excitement, fishermen from across the region gathered to kick off this long-awaited and traditional season.",
        "The launch event took place on our beautiful local coast and featured the participation of members from our fishing association, as well as enthusiasts and professional anglers. The ceremony marked the official start of the season with a friendly competition and high hopes for significant catches.",
        "“We’re thrilled to start another season. Pargo fishing presents both challenges and joys for anglers,” said João Silva, President of the Fishing Association. “With favorable conditions and the hard work of everyone involved in the preparations, we’re confident this will be an incredible season.”",
        "In addition to the competitions and challenges for the largest fish, this season also brings opportunities to promote sustainable fishing and marine ecosystem preservation. The association emphasizes the importance of following catch and release regulations to ensure the health of fish stocks for future generations.",
        "Fishermen have until the end of September to participate in the competitions and make the most of the season. Expectations are high, and early reports indicate a promising abundance of Pargo in local waters.",
        "For more information about events, regulations, and fishing tips, interested parties can visit our website or contact the association directly. Best of luck to all fishermen, and here’s to a season filled with great stories and impressive catches!",
      ],
      [
        "¡Después de meses de anticipación y preparación, la temporada de pesca de Pargo ha comenzado oficialmente! Ayer, con un clima ideal y una atmósfera de entusiasmo contagioso, pescadores de toda la región se reunieron para dar inicio a esta esperada y tradicional temporada.",
        "El evento de lanzamiento se llevó a cabo en nuestra hermosa costa local y contó con la participación de miembros de nuestra asociación de pescadores, así como entusiastas y profesionales de la pesca. La ceremonia marcó el inicio oficial de la temporada con una competencia amistosa y grandes expectativas de capturas significativas.",
        "“Estamos emocionados de comenzar otra temporada. La pesca de Pargo presenta tanto desafíos como alegrías para los pescadores”, dijo João Silva, presidente de la Asociación de Pescadores. “Con las condiciones favorables y el arduo trabajo de todos los involucrados en los preparativos, estamos seguros de que será una temporada increíble.”",
        "Además de las competiciones y desafíos para capturar los peces más grandes, esta temporada también brinda oportunidades para promover la pesca sostenible y la preservación de los ecosistemas marinos. La asociación enfatiza la importancia de respetar las normas de captura y liberación, asegurando la salud de las poblaciones de peces para las generaciones futuras.",
        "Los pescadores tienen hasta finales de septiembre para participar en las competiciones y aprovechar al máximo la temporada. Las expectativas son altas, y los informes iniciales indican una abundancia prometedora de Pargo en las aguas locales.",
        "Para obtener más información sobre eventos, regulaciones y consejos de pesca, los interesados pueden visitar nuestro sitio web o contactar a la asociación directamente. ¡Buena suerte a todos los pescadores, y que esta temporada esté llena de grandes historias y capturas impresionantes!",
      ],
    ],
    image: Pargo,
  },
  {
    type: types.news,
    title: [
      "ABRAPPAA visita presidente da Guandang",
      "ABRAPPAA visits president of Guandang",
      "ABRAPPAA visita al presidente de Guandang",
    ],
    date: ["01 de junho de 2024", "June 1, 2024", "01 de junio de 2024"],
    desc: [
      [
        "Após meses de expectativa e preparação, a temporada de pesca de Pargo finalmente começou! Ontem, com um clima ideal e uma atmosfera de entusiasmo contagiante, pescadores de toda a região se reuniram para dar início a essa tradicional e aguardada temporada.",
        "O evento de lançamento contou com a participação de associados da nossa associação de pescadores, bem como entusiastas e profissionais da pesca. A cerimônia, realizada na bela costa local, marcou o início oficial da temporada com uma competição amigável e a expectativa de grandes capturas.",
        "“Estamos animados para mais uma temporada. O Pargo é uma espécie que traz desafios e alegrias para os pescadores”, disse João Silva, presidente da Associação de Pescadores. “Com as condições favoráveis e o trabalho árduo de todos os envolvidos na preparação, temos certeza de que será uma temporada incrível.”",
        "Além das competições e desafios para os maiores peixes, a temporada também traz oportunidades para a promoção da pesca sustentável e a preservação dos ecossistemas marinhos. A associação enfatiza a importância de respeitar as regras de captura e soltura, garantindo a saúde dos estoques pesqueiros para as futuras gerações.",
        "Os pescadores têm até o final de setembro para participar das competições e aproveitar ao máximo a temporada. A expectativa é alta, e os relatos iniciais indicam uma abundância promissora de Pargo nas águas locais.",
        "Para mais informações sobre eventos, regulamentos e dicas de pesca, os interessados podem visitar nosso site ou entrar em contato com a associação diretamente. Boa sorte a todos os pescadores e que venha uma temporada repleta de grandes histórias e belos troféus!",
      ],
      [
        "After months of anticipation and preparation, the Pargo fishing season has officially begun! Yesterday, with ideal weather and an atmosphere of contagious excitement, fishermen from across the region gathered to kick off this long-awaited and traditional season.",
        "The launch event took place on our beautiful local coast and featured the participation of members from our fishing association, as well as enthusiasts and professional anglers. The ceremony marked the official start of the season with a friendly competition and high hopes for significant catches.",
        "“We’re thrilled to start another season. Pargo fishing presents both challenges and joys for anglers,” said João Silva, President of the Fishing Association. “With favorable conditions and the hard work of everyone involved in the preparations, we’re confident this will be an incredible season.”",
        "In addition to the competitions and challenges for the largest fish, this season also brings opportunities to promote sustainable fishing and marine ecosystem preservation. The association emphasizes the importance of following catch and release regulations to ensure the health of fish stocks for future generations.",
        "Fishermen have until the end of September to participate in the competitions and make the most of the season. Expectations are high, and early reports indicate a promising abundance of Pargo in local waters.",
        "For more information about events, regulations, and fishing tips, interested parties can visit our website or contact the association directly. Best of luck to all fishermen, and here’s to a season filled with great stories and impressive catches!",
      ],
      [
        "¡Después de meses de anticipación y preparación, la temporada de pesca de Pargo ha comenzado oficialmente! Ayer, con un clima ideal y una atmósfera de entusiasmo contagioso, pescadores de toda la región se reunieron para dar inicio a esta esperada y tradicional temporada.",
        "El evento de lanzamiento se llevó a cabo en nuestra hermosa costa local y contó con la participación de miembros de nuestra asociación de pescadores, así como entusiastas y profesionales de la pesca. La ceremonia marcó el inicio oficial de la temporada con una competencia amistosa y grandes expectativas de capturas significativas.",
        "“Estamos emocionados de comenzar otra temporada. La pesca de Pargo presenta tanto desafíos como alegrías para los pescadores”, dijo João Silva, presidente de la Asociación de Pescadores. “Con las condiciones favorables y el arduo trabajo de todos los involucrados en los preparativos, estamos seguros de que será una temporada increíble.”",
        "Además de las competiciones y desafíos para capturar los peces más grandes, esta temporada también brinda oportunidades para promover la pesca sostenible y la preservación de los ecosistemas marinos. La asociación enfatiza la importancia de respetar las normas de captura y liberación, asegurando la salud de las poblaciones de peces para las generaciones futuras.",
        "Los pescadores tienen hasta finales de septiembre para participar en las competiciones y aprovechar al máximo la temporada. Las expectativas son altas, y los informes iniciales indican una abundancia prometedora de Pargo en las aguas locales.",
        "Para obtener más información sobre eventos, regulaciones y consejos de pesca, los interesados pueden visitar nuestro sitio web o contactar a la asociación directamente. ¡Buena suerte a todos los pescadores, y que esta temporada esté llena de grandes historias y capturas impresionantes!",
      ],
    ],
    image: Presidente,
  },
  {
    type: types.article,
    title: [
      "Cresce consumo de Pargo no Brasil",
      "Pargo's consumption grows in Brazil",
      "Crece el consumo de Pargo en Brasil",
    ],
    date: ["05 de abril de 2024", "April 5, 2024", "05 de abril de 2024"],
    desc: [
      [
        "Após meses de expectativa e preparação, a temporada de pesca de Pargo finalmente começou! Ontem, com um clima ideal e uma atmosfera de entusiasmo contagiante, pescadores de toda a região se reuniram para dar início a essa tradicional e aguardada temporada.",
        "O evento de lançamento contou com a participação de associados da nossa associação de pescadores, bem como entusiastas e profissionais da pesca. A cerimônia, realizada na bela costa local, marcou o início oficial da temporada com uma competição amigável e a expectativa de grandes capturas.",
        "“Estamos animados para mais uma temporada. O Pargo é uma espécie que traz desafios e alegrias para os pescadores”, disse João Silva, presidente da Associação de Pescadores. “Com as condições favoráveis e o trabalho árduo de todos os envolvidos na preparação, temos certeza de que será uma temporada incrível.”",
        "Além das competições e desafios para os maiores peixes, a temporada também traz oportunidades para a promoção da pesca sustentável e a preservação dos ecossistemas marinhos. A associação enfatiza a importância de respeitar as regras de captura e soltura, garantindo a saúde dos estoques pesqueiros para as futuras gerações.",
        "Os pescadores têm até o final de setembro para participar das competições e aproveitar ao máximo a temporada. A expectativa é alta, e os relatos iniciais indicam uma abundância promissora de Pargo nas águas locais.",
        "Para mais informações sobre eventos, regulamentos e dicas de pesca, os interessados podem visitar nosso site ou entrar em contato com a associação diretamente. Boa sorte a todos os pescadores e que venha uma temporada repleta de grandes histórias e belos troféus!",
      ],
      [
        "After months of anticipation and preparation, the Pargo fishing season has officially begun! Yesterday, with ideal weather and an atmosphere of contagious excitement, fishermen from across the region gathered to kick off this long-awaited and traditional season.",
        "The launch event took place on our beautiful local coast and featured the participation of members from our fishing association, as well as enthusiasts and professional anglers. The ceremony marked the official start of the season with a friendly competition and high hopes for significant catches.",
        "“We’re thrilled to start another season. Pargo fishing presents both challenges and joys for anglers,” said João Silva, President of the Fishing Association. “With favorable conditions and the hard work of everyone involved in the preparations, we’re confident this will be an incredible season.”",
        "In addition to the competitions and challenges for the largest fish, this season also brings opportunities to promote sustainable fishing and marine ecosystem preservation. The association emphasizes the importance of following catch and release regulations to ensure the health of fish stocks for future generations.",
        "Fishermen have until the end of September to participate in the competitions and make the most of the season. Expectations are high, and early reports indicate a promising abundance of Pargo in local waters.",
        "For more information about events, regulations, and fishing tips, interested parties can visit our website or contact the association directly. Best of luck to all fishermen, and here’s to a season filled with great stories and impressive catches!",
      ],
      [
        "¡Después de meses de anticipación y preparación, la temporada de pesca de Pargo ha comenzado oficialmente! Ayer, con un clima ideal y una atmósfera de entusiasmo contagioso, pescadores de toda la región se reunieron para dar inicio a esta esperada y tradicional temporada.",
        "El evento de lanzamiento se llevó a cabo en nuestra hermosa costa local y contó con la participación de miembros de nuestra asociación de pescadores, así como entusiastas y profesionales de la pesca. La ceremonia marcó el inicio oficial de la temporada con una competencia amistosa y grandes expectativas de capturas significativas.",
        "“Estamos emocionados de comenzar otra temporada. La pesca de Pargo presenta tanto desafíos como alegrías para los pescadores”, dijo João Silva, presidente de la Asociación de Pescadores. “Con las condiciones favorables y el arduo trabajo de todos los involucrados en los preparativos, estamos seguros de que será una temporada increíble.”",
        "Además de las competiciones y desafíos para capturar los peces más grandes, esta temporada también brinda oportunidades para promover la pesca sostenible y la preservación de los ecosistemas marinos. La asociación enfatiza la importancia de respetar las normas de captura y liberación, asegurando la salud de las poblaciones de peces para las generaciones futuras.",
        "Los pescadores tienen hasta finales de septiembre para participar en las competiciones y aprovechar al máximo la temporada. Las expectativas son altas, y los informes iniciales indican una abundancia prometedora de Pargo en las aguas locales.",
        "Para obtener más información sobre eventos, regulaciones y consejos de pesca, los interesados pueden visitar nuestro sitio web o contactar a la asociación directamente. ¡Buena suerte a todos los pescadores, y que esta temporada esté llena de grandes historias y capturas impresionantes!",
      ],
    ],
    image: Peixes,
  },
  {
    type: types.news,
    title: [
      "Feliz dia das mães",
      "Happy mother's day",
      "Feliz dia de las madres",
    ],
    date: ["10 de maio de 2024", "May 10, 2024", "10 de mayo de 2024"],
    desc: [
      [
        "Após meses de expectativa e preparação, a temporada de pesca de Pargo finalmente começou! Ontem, com um clima ideal e uma atmosfera de entusiasmo contagiante, pescadores de toda a região se reuniram para dar início a essa tradicional e aguardada temporada.",
        "O evento de lançamento contou com a participação de associados da nossa associação de pescadores, bem como entusiastas e profissionais da pesca. A cerimônia, realizada na bela costa local, marcou o início oficial da temporada com uma competição amigável e a expectativa de grandes capturas.",
        "“Estamos animados para mais uma temporada. O Pargo é uma espécie que traz desafios e alegrias para os pescadores”, disse João Silva, presidente da Associação de Pescadores. “Com as condições favoráveis e o trabalho árduo de todos os envolvidos na preparação, temos certeza de que será uma temporada incrível.”",
        "Além das competições e desafios para os maiores peixes, a temporada também traz oportunidades para a promoção da pesca sustentável e a preservação dos ecossistemas marinhos. A associação enfatiza a importância de respeitar as regras de captura e soltura, garantindo a saúde dos estoques pesqueiros para as futuras gerações.",
        "Os pescadores têm até o final de setembro para participar das competições e aproveitar ao máximo a temporada. A expectativa é alta, e os relatos iniciais indicam uma abundância promissora de Pargo nas águas locais.",
        "Para mais informações sobre eventos, regulamentos e dicas de pesca, os interessados podem visitar nosso site ou entrar em contato com a associação diretamente. Boa sorte a todos os pescadores e que venha uma temporada repleta de grandes histórias e belos troféus!",
      ],
      [
        "After months of anticipation and preparation, the Pargo fishing season has officially begun! Yesterday, with ideal weather and an atmosphere of contagious excitement, fishermen from across the region gathered to kick off this long-awaited and traditional season.",
        "The launch event took place on our beautiful local coast and featured the participation of members from our fishing association, as well as enthusiasts and professional anglers. The ceremony marked the official start of the season with a friendly competition and high hopes for significant catches.",
        "“We’re thrilled to start another season. Pargo fishing presents both challenges and joys for anglers,” said João Silva, President of the Fishing Association. “With favorable conditions and the hard work of everyone involved in the preparations, we’re confident this will be an incredible season.”",
        "In addition to the competitions and challenges for the largest fish, this season also brings opportunities to promote sustainable fishing and marine ecosystem preservation. The association emphasizes the importance of following catch and release regulations to ensure the health of fish stocks for future generations.",
        "Fishermen have until the end of September to participate in the competitions and make the most of the season. Expectations are high, and early reports indicate a promising abundance of Pargo in local waters.",
        "For more information about events, regulations, and fishing tips, interested parties can visit our website or contact the association directly. Best of luck to all fishermen, and here’s to a season filled with great stories and impressive catches!",
      ],
      [
        "¡Después de meses de anticipación y preparación, la temporada de pesca de Pargo ha comenzado oficialmente! Ayer, con un clima ideal y una atmósfera de entusiasmo contagioso, pescadores de toda la región se reunieron para dar inicio a esta esperada y tradicional temporada.",
        "El evento de lanzamiento se llevó a cabo en nuestra hermosa costa local y contó con la participación de miembros de nuestra asociación de pescadores, así como entusiastas y profesionales de la pesca. La ceremonia marcó el inicio oficial de la temporada con una competencia amistosa y grandes expectativas de capturas significativas.",
        "“Estamos emocionados de comenzar otra temporada. La pesca de Pargo presenta tanto desafíos como alegrías para los pescadores”, dijo João Silva, presidente de la Asociación de Pescadores. “Con las condiciones favorables y el arduo trabajo de todos los involucrados en los preparativos, estamos seguros de que será una temporada increíble.”",
        "Además de las competiciones y desafíos para capturar los peces más grandes, esta temporada también brinda oportunidades para promover la pesca sostenible y la preservación de los ecosistemas marinos. La asociación enfatiza la importancia de respetar las normas de captura y liberación, asegurando la salud de las poblaciones de peces para las generaciones futuras.",
        "Los pescadores tienen hasta finales de septiembre para participar en las competiciones y aprovechar al máximo la temporada. Las expectativas son altas, y los informes iniciales indican una abundancia prometedora de Pargo en las aguas locales.",
        "Para obtener más información sobre eventos, regulaciones y consejos de pesca, los interesados pueden visitar nuestro sitio web o contactar a la asociación directamente. ¡Buena suerte a todos los pescadores, y que esta temporada esté llena de grandes historias y capturas impresionantes!",
      ],
    ],
    image: DiaDasMaes,
  },
];

export const seeMore = ["Saiba mais", "See more", "Vea mas"];
