export enum Languages {
  PT,
  EN,
  ES,
}

export const MenuTexts = {
  inicio: ["Início", "Home", "Comienzo"],
  noticias: ["Notícias", "News", "Noticias"],
  quemSomos: ["Quem somos", "About us", "Sobre nosotros"],
  estatisticas: ["Estatísticas", "Statistics", "Estadísticas"],
  associe: ["Associe-se", "Join", "Unirse"],
};

export const HeroTexts = {
  title: [
  ],
  desc: [
    "A Amazônia Azul que nos une na busca da produção de alimentos e do sustento, fez com que nos uníssemos também no diagnóstico dos nossos problemas, no planejamento e na execução da solução deles.",
    "The Blue Amazon, which unites us in the search for food production and sustenance, also made us unite in diagnosing our problems, planning and executing their solutions.",
    "La Amazonía Azul, que nos une en la búsqueda de la producción y el sustento de alimentos, también nos hizo unirnos en el diagnóstico de nuestros problemas, la planificación y ejecución de sus soluciones.",
  ],
  btn: ["Saiba mais", "See more", "Vea mas"],
};

export const AboutTexts = {
  title: ["SOBRE NÓS", "ABOUT US", "SOBRE NOSOTROS"],
  desc: [
    "Nosso atendimento e proximidade com nossos clientes são nossos diferenciais, desde 2018 somos uma empresa que entende a necessidade do nosso cliente e busca sempre as melhores ofertas",
    "Our service and proximity to our customers are our differentiators, since 2018 we have been a company that understands our customer's needs and always seeks the best offers",
    "Nuestro servicio y cercanía con nuestros clientes son nuestros diferenciadores, desde 2018 somos una empresa que entiende las necesidades de nuestros clientes y busca siempre las mejores ofertas",
  ],
  btn: ["Saiba mais", "See more", "Vea mas"],
};

export const DataTexts = {
  title: [
    "Acesse nossas informações e dados",
    "Access our information and data",
    "Accede a nuestra información y datos",
  ],
  desc: [
    "Para ajudar nossos associados disponibilizamos informações da temporada de pesca",
    "To help our members we provide information about the fishing season",
    "Para ayudar a nuestros miembros proporcionamos información sobre la temporada de pesca",
  ],
  btn: ["Nossos dados", "Our data", "Nuestros datos"],
};

export const RegisterTexts = {
  title: [
    "Seja um associado da ABRAPPAA",
    "Become an ABRAPPAA member",
    "Conviértete en miembro de ABRAPPAA",
  ],
  desc: [
    "Venha fazer parte dessa comunidade",
    "Come be part of this community",
    "Ven a ser parte de esta comunidad",
  ],
  btn: ["Seja um associado", "Become an associate", "Conviértete en asociado"],
};

export const FooterTexts = {
  atendimento: ["Atendimento", "Service", "Servicio"],
  atendimentoDay: ["Segunda à sexta", "Monday to friday", "Lunes a viernes"],
  atendimentoHour: [
    "08h às 12h - 15h às 18h",
    "8am to 12pm - 3pm to 6pm",
    "8am a 12pm - 3pm to 6pm",
  ],
  redes: ["Redes sociais", "Social media", "Redes sociales"],
  rua: ["Av. Beira Mar", "Beira Mar Av.", "Av. Beira Mar"],
  cep: ["CEP", "Zip Code", "Código postal"],
};

export const CopyRightText = [
  "Todos os direitos reservados. Feito por",
  "All rights reserved. Made by",
  "Todos los derechos reservados. Hecho por",
];

export const AssociatesTexts = ["ASSOCIADOS", "ASSOCIATES", "ASOCIADOS"];
