import styled from "styled-components";
import { COLORS, FONT_FAMILY } from "../../theme";

const width = (window.innerWidth * 0.9 * 858) / 1400 + 50;

export const Section = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px - 296px - 52px);
  padding: 48px 0;
  font-family: ${FONT_FAMILY.MONTSERRAT};

  iframe {
    margin-top: 24px;
    width: 100%;
    height: 858px;

    @media (max-width: 1400px) {
      height: 736px;
    }

    @media (max-width: 1200px) {
      height: ${width + "px"};
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: ${COLORS.BLUE};
`;
