export const abrappaData = {
  email: "abrappaa@gmail.com",
  whatsapp: "https://api.whatsapp.com/send?phone=5591998627200",
  address: {
    street: "Av. Beira Mar",
    neighborhood: "Vila de Bacuriteura",
    number: "390-A",
    city: "Bragança",
    state: "PA",
    cep: "68600-000",
    link: "https://maps.app.goo.gl/FnoB895joWWuqimR8",
  },
};

export const linkForms = "https://forms.gle/x4m1cQ42638NZBVR8";
