import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

export const Section = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px - 296px - 52px);
  padding: 48px 0;
  font-family: ${FONT_FAMILY.MONTSERRAT};
`;

export const Title = styled.h1`
  text-align: center;
  color: ${COLORS.BLUE};
`;

export const AssociateContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 32px 0;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 1.5%;

  @media (max-width: 950px) {
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    padding-bottom: 120px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 48% 48%;
    gap: 12px;
    padding-bottom: 200px;
  }

  @media (max-width: 359px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 16px;
  }
`;

export const AssociateMember = styled.div`
  cursor: pointer;
  margin-top: 16px;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
  transition: 0.4s all;

  &:hover {
    transition: 0.4s all;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 950px) {
    margin-top: 0px;
  }
`;

export const AssociateBox = styled.div`
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  p {
    margin-top: 24px;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    text-align: center;
  }
`;
