import { styled } from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../../theme";

export const Background = styled.div`
  background-color: #f1e8e8;
  padding: 64px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 50%;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    img {
      display: none;
    }
  }
`;

export const AboutInfo = styled.div`
  width: 45%;
  color: ${COLORS.BLUE};
  font-family: ${FONT_FAMILY.MONTSERRAT};
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 28px;
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};

      @media (max-width: 1400px) {
        font-size: 24px;
      }

      @media (max-width: 950px) {
        text-align: center;
      }
    }

    p {
      font-size: 20px;
      font-weight: ${FONT_WEIGHT.REGULAR};
      margin: 38px 0;
      line-height: 28px;

      @media (max-width: 1400px) {
        font-size: 18px;
      }

      @media (max-width: 950px) {
        font-size: 16px;
        text-align: center;
        margin: 24px 0;
      }
    }
  }
`;
