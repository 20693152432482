import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../../theme";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import ArrowLeft from "../../../assets/images/arrow-left.svg";

export const Section = styled.div`
  width: 100%;
  background-color: ${COLORS.BLUE};
  color: #fff;
  padding: 32px 0;
  font-family: ${FONT_FAMILY.INTER};
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.slick-slider {
    button.slick-arrow {
      border: 1px solid #fff;
      background-image: url(${ArrowRight});
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &::before {
        opacity: 1;
        content: "";
      }
    }

    button.slick-arrow.slick-prev {
      background-image: url(${ArrowLeft});
      z-index: 9;
    }
  }
`;

export const Title = styled.h1`
  font-size: 24px;
`;

export const GetToKnow = styled.a`
  color: #fff;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  text-decoration: none;
  margin-top: 16px;
  padding: 8px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 950px) {
    margin-top: 32px;
  }
`;

// SLIDER
export const SliderBox = styled.div`
  width: 92%;
  margin: 32px auto;
`;

export const SlideContainer = styled.div`
  width: 90%;
  padding: 16px 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  p {
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: 18px;
    margin-left: 16px;

    @media (max-width: 950px) {
      margin-left: 8px;
    }
  }

  &:hover {
    padding: 16px 0 14px 0;
    border-bottom: 2px solid ${COLORS.RED};
  }
`;

export const Dots = styled.div`
  border-radius: 12px;
  padding: 12px 0;

  @media (max-width: 950px) {
    padding: 18px 0;
  }
`;

export const Dot = styled.ul`
  margin-bottom: -42px;

  li button:before {
    font-size: 12px;
    opacity: 1;
    color: #d7d7d7;
  }

  li.slick-active button:before {
    color: ${COLORS.RED};
    opacity: 1;
  }
`;

// MODAL
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${FONT_FAMILY.MONTSERRAT};

  div.slick-slider {
    button.slick-arrow {
      background-image: url(${ArrowRight});
      background-color: ${COLORS.BLUE};
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &::before {
        opacity: 1;
        content: "";
      }
    }

    button.slick-arrow.slick-prev {
      background-image: url(${ArrowLeft});
      z-index: 9;
    }
  }

  h1 {
    font-size: 24px;
    margin-top: 8px;
    color: ${COLORS.BLUE};

    @media (max-width: 950px) {
      font-size: 20px;
    }
  }
`;

export const AssociatePhoto = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const Boats = styled.div`
  width: 100%;

  img {
    width: 96%;
    margin: 0 auto;
    border-radius: 10px;
  }

  span {
    margin-left: 2%;
  }
`;

export const Description = styled.div`
  width: 96%;
  background-color: ${COLORS.GRAY};
  margin: 24px auto 0 auto;
  border-radius: 10px;

  p {
    padding: 16px;
    line-height: 24px;
  }
`;

export const NoBoats = styled.p`
  text-align: center;
  margin-top: 32px;
  font-size: 18px;
`;
