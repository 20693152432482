import styled from "styled-components";
import Image from "../../../assets/images/background.png";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../../theme";

export const BackgroundImg = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  background: url(${Image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1400px) {
    height: 98vh;
  }

  @media (max-width: 950px) {
    height: 90vh;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONT_FAMILY.INTER};
  width: 36%;
  padding: 100px 0;
  color: ${COLORS.BLUE};
  background: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 77%
  );

  @media (max-width: 1400px) {
    margin-top: 64px;
  }

  @media (max-width: 950px) {
    margin-top: 0px;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 42px;
  font-weight: ${FONT_WEIGHT.BOLD};

  @media (max-width: 1400px) {
    font-size: 32px;
  }

  @media (max-width: 950px) {
    font-size: 28px;
    text-align: center;
  }
`;

export const Desc = styled.p`
  font-size: 24px;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  margin: 32px 0;
  line-height: 36px;

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 950px) {
    line-height: 28px;
    text-align: center;
    margin: 28px 0;
  }
`;
