import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Container from "../../components/Container";
import Clock from "../../assets/images/relogio.svg";
import {
  Card,
  CardImage,
  CardInfo,
  Cards,
  ClockImage,
  Label,
  Section,
} from "./style";
import { Link } from "react-router-dom";
import { allNews, seeMore } from "../../mocks/newsTexts";
import { newsLink } from "../../utils/newsLink";
import { useLanguageContext } from "../../contexts/languageContext";

const Allnews = () => {
  const { lang } = useLanguageContext();

  return (
    <>
      <Header notHome />
      <Section>
        <Container>
          <div className="cards">
            <Cards>
              {allNews.map((news) => (
                <Card
                  onClick={() =>
                    window.open(`noticia/${newsLink(news?.title[0])}`, "_blank")
                  }
                >
                  <CardImage background={news.image} />
                  <CardInfo>
                    <Label className={news?.type[0]}>{news.type[lang]}</Label>
                    <p>{news.title[lang]}</p>
                    <span>
                      <ClockImage src={Clock} alt="clock" />
                      {news.date[lang]}
                      <a href="#">{seeMore[lang]} →</a>
                    </span>
                  </CardInfo>
                </Card>
              ))}
            </Cards>
          </div>
        </Container>
      </Section>
      <Footer />
    </>
  );
};

export default Allnews;
