import Home from "./pages/Home";
import { LanguageProvider } from "./contexts/languageContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Associados from "./pages/Associados";
import Stats from "./pages/Stats";
import News from "./pages/News";
import Allnews from "./pages/Allnews";

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="associados" Component={Associados} />
          <Route path="estatisticas" Component={Stats} />
          <Route path="noticia/:id" Component={News} />
          <Route path="allnews" Component={Allnews} />
          <Route path="*" Component={Home} />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
