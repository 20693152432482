import { styled } from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";
import React from "react";

export type ButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  title?: string;
  familyInter?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
};

export const Content = styled.a<ButtonProps>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "18px")};
  font-family: ${({ familyInter }) =>
    familyInter ? FONT_FAMILY.INTER : FONT_FAMILY.MONTSERRAT};
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.WHITE};
  background-color: ${COLORS.RED};
  border-radius: ${({ height }) => (height ? "10px" : "20px")};
  height: ${({ height }) => (height ? height + "px" : "56px")};
  width: ${({ width }) => (width ? width + "px" : "100%")};
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
  transition: 0.4s all;

  &:hover {
    transition: 0.4s all;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 750px) {
    width: ${({ height }) => (height ? "90%" : "100%")};
  }
`;
