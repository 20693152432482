import { styled } from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

type MenuProps = {
  isOpen: boolean;
};

export type HeaderProps = {
  notHome?: boolean;
  topRef?: React.MutableRefObject<any>;
  newsRef?: React.MutableRefObject<any>;
  aboutRef?: React.MutableRefObject<any>;
};

export const Head = styled.div<HeaderProps>`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 6px 0;
  position: ${({ notHome }) => (!notHome ? "fixed" : "static")};
  z-index: 999;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
`;

export const FlexSpaced = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const Flex = styled.div<MenuProps>`
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    width: 100%;
    height: 100%;
    position: fixed;
    flex-direction: column;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? 0 : `-${window.innerWidth + 10}px`)};
    margin-top: 120px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.85);
    transition: 0.4s all;
  }
`;

export const LogoImg = styled.img`
  width: 68px;
  cursor: pointer;
`;

export const Item = styled.p`
  cursor: pointer;
  font-family: ${FONT_FAMILY.MONTSERRAT};
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 16px;
  padding: 8px;
  margin: 0 8px;

  &:first-of-type {
    margin: 0 8px 0 0;
  }

  &:last-of-type {
    margin: 0 16px 0 8px;
  }

  &:hover {
    border-bottom: 2px solid black;
    padding: 8px 8px 6px 8px;
  }

  @media (max-width: 750px) {
    width: 100%;
    padding: 12px 0;
    margin: 0;
    text-align: center;
    border-bottom: 1px solid ${COLORS.GRAY};

    &:first-of-type,
    &:last-of-type {
      margin: 0;
    }
  }
`;

export const MenuImg = styled.img`
  width: 32px;
  padding: 8px 0 8px 8px;
  display: none;

  @media (max-width: 750px) {
    display: block;
  }
`;

export const SelectedLang = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;
  border-bottom: 1px solid #e7e7e7;
`;

export const Languages = styled.img`
  cursor: pointer;
  width: 24px;
  margin-right: 8px;
`;
