import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { allNews } from "../../mocks/newsTexts";
import { newsLink } from "../../utils/newsLink";
import { useLanguageContext } from "../../contexts/languageContext";
import Clocking from "../../assets/images/relogio.svg";
import {
  Clock,
  Container,
  Flex,
  Label,
  NewsImg,
  NoNews,
  Section,
  Texts,
  Title,
} from "./style";

export default function News() {
  const { id } = useParams();
  const { lang } = useLanguageContext();

  const selectedNews = allNews.find((news) => newsLink(news.title[0]) === id);

  return (
    <>
      <Header notHome />
      <Section>
        <Container>
          {selectedNews ? (
            <>
              <Title>{selectedNews.title[lang]}</Title>
              <Flex className="spaced">
                <Label className={selectedNews.type[0]}>
                  {selectedNews.type[lang]}
                </Label>
                <Flex>
                  <Clock src={Clocking} alt="clock" />
                  <span>{selectedNews.date[lang]}</span>
                </Flex>
              </Flex>
              <NewsImg src={selectedNews.image} alt="news image" />
              <Texts>
                {selectedNews.desc[lang].map((text) => (
                  <p>{text}</p>
                ))}
              </Texts>
            </>
          ) : (
            <NoNews>Notícia não encontrada</NoNews>
          )}
        </Container>
      </Section>
      <Footer />
    </>
  );
}
