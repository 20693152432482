import Container from "../Container";
import { Flex, Info, Infos, LogoImg, Section } from "./style";
import Logo from "../../assets/images/logo.svg";
import { abrappaData } from "../../mocks/abrappaData";
import Copyright from "../Copyright";
import Instagram from "../../assets/images/instagram.svg";
import { FooterTexts } from "../../mocks/texts";
import { useLanguageContext } from "../../contexts/languageContext";

type FooterProps = {
  topRef?: React.MutableRefObject<any>;
};

export default function Footer({ topRef }: FooterProps) {
  const { lang } = useLanguageContext();

  return (
    <>
      <Section>
        <Container>
          <Flex>
            <LogoImg
              src={Logo}
              alt="Abrappa Logo"
              onClick={() => {
                topRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
            <Infos>
              <Info className="atendimento">
                <b>{FooterTexts.atendimento[lang]}:</b>
                <br />
                {FooterTexts.atendimentoDay[lang]}
                <br />
                {FooterTexts.atendimentoHour[lang]}
              </Info>
              <Info>
                <b>{FooterTexts.redes[lang]}:</b>
                <br />
                <a
                  href="https://www.instagram.com/abrappaa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="instagram" width={28} />
                </a>
              </Info>
              <Info>
                <b>Local:</b>
                <br />
                <a
                  href={abrappaData.address.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {FooterTexts.rua[lang]}
                  <br />
                  {abrappaData.address.neighborhood}, nº{" "}
                  {abrappaData.address.number}
                  <br />
                  {abrappaData.address.city} - {abrappaData.address.state}
                  <br />
                  {FooterTexts.cep[lang]}: {abrappaData.address.cep}
                </a>
              </Info>
            </Infos>
          </Flex>
        </Container>
      </Section>
      <Copyright />
    </>
  );
}
